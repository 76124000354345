import { Box, Popover, Skeleton, TextField, Typography } from '@mui/material';
import { useCategoryQuery } from 'common/hooks/api/queries/use-category-query';
import { Styles } from 'common/types/styles';
import { FieldLabelWrapper } from 'common/ui/inputs/field-label-wrapper';
import SaveIcon from '@mui/icons-material/Save';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { ChangeEvent, ReactElement, useEffect, useMemo, useState } from 'react';
import {
  useAttributeFlagsPatchMutation,
  useEnhanceSpecialInstructionMutation,
  useGenerateAIForCategoryMutation,
  useGenerateAIForTCIN,
  useGenerateAIForWPIDSingleAttributeMutation,
  useGenerateTargetAIForCategoryMutation,
  useRestoreAIGenerationMutation,
  useRetailerAttributeFlagsPatchMutation,
  useSendEnhanceNotificationMutation,
} from 'common/hooks/api/mutations/use-attributes-mutation';
import { ButtonWithLoading } from 'common/ui/containers/button-with-loading';
import { useQueryClient } from '@tanstack/react-query';
import { ContentQueryKey, useContentQuery } from 'common/hooks/api/queries/use-content-query';
import { userService } from 'common/services/user.service';
import { ProductContentStatus, RetailerType, selectAllValue } from 'common/constants/entities';
import {
  GenerateAIForWPIDSingleAttributeBody,
  GenerateAiResponse,
  GenereateAIForTCINBody,
} from 'common/services/api/attributes/attributes-api.types';
import { AxiosResponse } from 'axios';
import { ProductsQueryKey } from 'common/hooks/api/queries/use-products-query';
import { theme } from 'common/constants/theme';
import toast from 'react-hot-toast';
import { useScopeContext } from 'common/ui/inputs/scopes/scope-ctx';
import { extractAttributeFromBackend, getPDPValues } from 'common/services/attributes.service';

type Notification = {
  prev: {
    instruction: string;
    attribute: string;
  };
  new: {
    instruction: string;
    attribute: string;
  };
  status: 'pending' | 'ready';
  comment: string;
};

const getInitialNotification = (): Notification => ({
  prev: {
    instruction: '',
    attribute: '',
  },
  new: {
    instruction: '',
    attribute: '',
  },
  status: 'pending',
  comment: '',
});

const styles: Styles = {
  container: { width: 700, p: 2, boxSizing: 'border-box', display: 'flex', flexDirection: 'column', gap: 2 },
  btns: { display: 'flex', gap: 2 },
  btn: { flexGrow: 1 },
  regenerateBatch: { color: theme.palette.primary.main, cursor: 'pointer' },
  batchContainer: { display: 'flex', flexDirection: 'column', gap: 1 },
};

interface Props {
  anchorEl: HTMLElement;
  onClose: () => void;
  pid: string;
  categoryID: number | string;
  attributeKey: string;
  functions: Array<'instruction' | 'attribute' | 'propagateBatch' | 'propagateCategory'>;
  functionsParams?: {
    propagateBatch?: {
      pids: string[];
    };
  };
  pdp?: boolean;
  retailer?: RetailerType;
}

export function AttributeEnhancePopup({
  anchorEl,
  onClose,
  pid,
  categoryID,
  attributeKey,
  functions,
  pdp,
  retailer,
  functionsParams,
}: Props): ReactElement {
  const queryClient = useQueryClient();

  const { scope, wsScope } = useScopeContext();
  const { data: categoryData } = useCategoryQuery({ id: categoryID, enabled: Boolean(anchorEl), retailer });
  const { data: contentData } = useContentQuery({ pid, enabled: true });

  const { mutate: mutateEnhanceInstruction, isLoading: isEnhanceInstructionLoading } =
    useEnhanceSpecialInstructionMutation(retailer);
  const { mutate: mutateWalmartAttributePatch, isLoading: isWalmartAttributePatchLoading } =
    useAttributeFlagsPatchMutation();
  const { mutate: mutateRetailerAttributePatch, isLoading: isRetailerAttributePatchLoading } =
    useRetailerAttributeFlagsPatchMutation();
  const { mutate: mutateRestoreAIGeneration, isLoading: isRestoreAIGenerationLoading } =
    useRestoreAIGenerationMutation();
  const { mutate: mutateGenerateWalmartAI, isLoading: isWalmartGenerateAILoading } =
    useGenerateAIForWPIDSingleAttributeMutation();
  const { mutate: mutateGenerateRetailerAI, isLoading: isRetailerGenerateAILoading } = useGenerateAIForTCIN(retailer);
  const { mutate: mutateGenerateBatchAI } = useGenerateAIForCategoryMutation();
  const { mutate: mutateGenerateBatchRetailerAI } = useGenerateTargetAIForCategoryMutation();
  const { mutate: sendNotification } = useSendEnhanceNotificationMutation(retailer);

  const [isUpdInstruction, setIsUpdInstruction] = useState<boolean>(false);
  const [enhancedInstruction, setEnhancedInstruction] = useState<string>('');
  const [enhancedAttribute, setEnhancedAttribute] = useState<string>('');
  const [generatedAt, setGeneratedAt] = useState<string>('');
  const [comment, setComment] = useState<string>('');
  const [notification, setNotification] = useState<Notification>(getInitialNotification());

  const attributeID = (categoryData ?? {})[attributeKey]?.id;
  const attributeInstructions = (categoryData ?? {})[attributeKey]?.settings?.ai_special_instructions ?? '';
  const isGenerateAILoading = isWalmartGenerateAILoading || isRetailerGenerateAILoading;
  const sourceRetailer = retailer || userService.getAPIRetailer();
  const isWalmart = [RetailerType.Walmart, RetailerType.Flywheel].includes(sourceRetailer as RetailerType);

  const handleCommentChange = (e: ChangeEvent<HTMLInputElement>) => {
    setComment(e.target.value);
  };

  const pdpAttributeKey = useMemo(() => {
    switch (attributeKey) {
      case 'product_title':
        return 'title';
        break;
      case 'product_short_description':
        return 'description';
        break;
      case 'product_long_description':
        return 'features';
        break;
      default:
        return '';
    }
  }, [attributeKey]);

  const handleEnhance = () => {
    if (!comment) return;

    setNotification(notification => ({ ...notification, comment }));

    if (functions.includes('instruction')) {
      mutateEnhanceInstruction(
        {
          ...(isWalmart ? { wpid: pid } : { tcin: pid }),
          attributeName: attributeKey,
          comment,
          sub_vendor_id: userService.getSubRetailer(),
        },
        {
          onSuccess: ({ data }) => {
            setNotification(notification => ({
              ...notification,
              new: { ...notification.new, instruction: data.specialInstruction },
              prev: { ...notification.prev, instruction: attributeInstructions ?? '-' },
              status: notification.new.attribute || !functions.includes('attribute') ? 'ready' : 'pending',
            }));
            setIsUpdInstruction(true);
            setEnhancedInstruction(data.specialInstruction);
          },
        }
      );
    }

    if (functions.includes('attribute')) {
      const commonAttributeBody: Partial<GenerateAIForWPIDSingleAttributeBody & GenereateAIForTCINBody> = {
        generateAttributes: !pdp,
        optimizeAttributes: pdp,
        overwriteGeneratedAttributes: !pdp,
        overwriteOptimizedAttributes: pdp,
        dryRun: true,
        ...(isWalmart ? { wpid: pid } : { tcin: pid }),
        ...(pdp ? {} : { attributeName: attributeKey }),
        ...(pdp
          ? {
              pdpAttributeName: attributeKey,
              pdpAttributesWithIssuesOnly: false,
            }
          : {}),
        comments: {
          [attributeKey]: comment,
        },
      };

      const handleAttributeSuccess = ({ data }: AxiosResponse<GenerateAiResponse>) => {
        let res = '';
        let prev = '';

        if (pdp) {
          res =
            (data?.aiContentPreview?.optimizations?.optimized ?? {})[pdpAttributeKey as unknown as 'title']?.value ??
            (data?.aiContentPreview?.optimizations?.seo_optimized ?? {})[pdpAttributeKey as unknown as 'title']?.value;

          const pdp = getPDPValues(contentData);

          switch (attributeKey) {
            case 'product_title':
              prev = pdp.ai.title ?? '-';
              break;
            case 'product_short_description':
              prev = pdp.ai.desc ?? '-';
              break;
            case 'product_long_description':
              prev = pdp.ai.feats ?? '-';
              break;
            default:
              prev = null;
          }
        } else {
          const attribute = (data?.aiContentPreview?.processed ?? {})[attributeKey];
          res = `${attribute?.value ?? ''}${attribute.unit_of_measure ?? ''}`;
          prev = String(
            extractAttributeFromBackend(attributeKey, (categoryData ?? {})[attributeKey], contentData).formValue
          );
        }

        if (pdp) {
          const date =
            (data?.aiContentPreview?.optimizations?.optimized ?? {})[pdpAttributeKey as unknown as 'title']
              ?.generated_at ??
            (data?.aiContentPreview?.optimizations?.seo_optimized ?? {})[pdpAttributeKey as unknown as 'title']
              ?.generated_at;
          setGeneratedAt(date ?? '');
        } else {
          setGeneratedAt((data?.aiContentPreview?.processed ?? {})[attributeKey]?.generatedAt ?? '');
        }
        setEnhancedAttribute(res);
        setNotification(notification => ({
          ...notification,
          new: {
            ...notification.new,
            attribute: res ?? '-',
          },
          prev: {
            ...notification.prev,
            attribute: prev ?? '-',
          },
          status: notification.new.instruction || !functions.includes('instruction') ? 'ready' : 'pending',
        }));

        queryClient.invalidateQueries([ContentQueryKey.Content, pid]);
        queryClient.invalidateQueries([ContentQueryKey.ContentList]);
        queryClient.invalidateQueries([ProductsQueryKey.GetProducts]);
      };

      if (isWalmart) {
        mutateGenerateWalmartAI(commonAttributeBody as GenerateAIForWPIDSingleAttributeBody, {
          onSuccess: handleAttributeSuccess,
        });
      } else {
        mutateGenerateRetailerAI(commonAttributeBody as GenereateAIForTCINBody, { onSuccess: handleAttributeSuccess });
      }
    }
  };

  const handleInstructionSave = () => {
    const handleSuccess = () => {
      toast.success('Special Instruction saved successfully');

      queryClient.invalidateQueries([ContentQueryKey.Content, pid]);
      queryClient.invalidateQueries([ContentQueryKey.ContentList]);
      queryClient.invalidateQueries([ProductsQueryKey.GetProducts]);
    };

    if (isWalmart) {
      mutateWalmartAttributePatch(
        {
          attribute_id: attributeID,
          ai_special_instructions: enhancedInstruction,
        },
        { onSuccess: handleSuccess }
      );
    } else {
      mutateRetailerAttributePatch(
        {
          attribute_id: attributeID,
          ai_special_instructions: enhancedInstruction,
        },
        { onSuccess: handleSuccess }
      );
    }
  };

  const handleAttributeSave = () => {
    const handleSuccess = () => {
      toast.success('Attribute saved successfully');

      queryClient.invalidateQueries([ContentQueryKey.Content, pid]);
      queryClient.invalidateQueries([ContentQueryKey.ContentList]);
      queryClient.invalidateQueries([ProductsQueryKey.GetProducts]);
    };

    mutateRestoreAIGeneration(
      {
        ...(isWalmart ? { wpid: pid } : { tcin: pid }),
        attributeName: attributeKey,
        generatedAt: generatedAt ?? '',
      },
      { onSuccess: handleSuccess }
    );
  };

  const handleRegenerateBatch = (pids?: string[]) => {
    const commonParams = {
      categoriesIds: [categoryID],
      limit: pids ? pids.length : undefined,
      updateStatus: false,
      generateAttributes: true,
      optimizeAttributes: false,
      priority: 1,
      overwriteGeneratedAttributes: true,
      attributeList: [attributeKey],
      statuses: [ProductContentStatus.ReadyForAI, ProductContentStatus.ReadyForReview],
      sub_vendor_id: userService.getSubRetailer(),
    };

    const handleSuccess = () => {
      toast.success('Successfully running AI job');
    };

    if (isWalmart) {
      mutateGenerateBatchAI(
        {
          ...commonParams,
          wsScopeId: wsScope?.ws_scope,
          ...(scope?.id === selectAllValue || !scope?.id ? {} : { scopes: String(scope?.id) }),
          ...(pids ? { wpids: pids } : {}),
        },
        { onSuccess: handleSuccess }
      );
    } else {
      mutateGenerateBatchRetailerAI(
        {
          ...commonParams,
          ...(pids ? { tcins: pids } : {}),
        },
        { onSuccess: handleSuccess }
      );
    }
  };

  const handleEnhancedInstructionChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEnhancedInstruction(e.target.value);
  };

  useEffect(() => {
    if (anchorEl) return;

    setComment('');
    setEnhancedAttribute('');
    setGeneratedAt('');
    setIsUpdInstruction(false);
    setEnhancedInstruction('');
  }, [anchorEl]);

  useEffect(() => {
    if (notification.status === 'pending') return;

    sendNotification({
      attributeName: attributeKey,
      comment: notification.comment,
      currentAttributeValue: notification.prev.attribute,
      enhancedAttributeValue: notification.new.attribute,
      ...(functions.includes('instruction')
        ? {
            currentSpecialInstruction: notification.prev.instruction,
            enhancedSpecialInstruction: notification.new.instruction,
          }
        : {}),
    });

    setNotification(getInitialNotification());
  }, [attributeKey, functions, notification, sendNotification]);

  return (
    <Popover anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onClose}>
      <Box sx={styles.container}>
        <FieldLabelWrapper label="Comment">
          <TextField minRows={3} maxRows={3} onChange={handleCommentChange} value={comment} multiline fullWidth />
        </FieldLabelWrapper>
        {functions.includes('attribute') && (
          <FieldLabelWrapper label="Enhanced Attribute">
            {!enhancedAttribute && !isGenerateAILoading && '-'}
            {isGenerateAILoading && <Skeleton variant="text" />}
            {!isGenerateAILoading && enhancedAttribute && <Typography fontSize={14}>{enhancedAttribute}</Typography>}
          </FieldLabelWrapper>
        )}
        {functions.includes('instruction') && (
          <>
            <FieldLabelWrapper label="Existing Instruction">
              <Typography fontSize={14}>{attributeInstructions?.length ? attributeInstructions : '-'}</Typography>
            </FieldLabelWrapper>
            <FieldLabelWrapper label="Enhanced Instruction">
              {!isUpdInstruction && !enhancedInstruction && !isEnhanceInstructionLoading && '-'}
              {isEnhanceInstructionLoading && <Skeleton variant="text" />}
              {!isUpdInstruction && !isEnhanceInstructionLoading && enhancedInstruction && (
                <Typography fontSize={14}>{enhancedInstruction}</Typography>
              )}
              {isUpdInstruction && !isEnhanceInstructionLoading && enhancedInstruction && (
                <TextField
                  variant="standard"
                  value={enhancedInstruction}
                  onChange={handleEnhancedInstructionChange}
                  sx={styles.input}
                />
              )}
            </FieldLabelWrapper>
          </>
        )}
        {functions.includes('propagateBatch') && functionsParams?.propagateBatch && enhancedInstruction && (
          <Box sx={styles.batchContainer}>
            <Typography fontSize={14}>
              Do you want to{' '}
              <Typography
                component="span"
                fontSize="inherit"
                sx={styles.regenerateBatch}
                onClick={() => handleRegenerateBatch(functionsParams.propagateBatch.pids)}
              >
                re-generate
              </Typography>{' '}
              for batch?
            </Typography>
            {functions.includes('propagateBatch') && functions.includes('propagateCategory') && (
              <Typography fontSize={14}>or</Typography>
            )}
            <Typography fontSize={14}>
              Do you want to{' '}
              <Typography
                component="span"
                fontSize="inherit"
                sx={styles.regenerateBatch}
                onClick={() => handleRegenerateBatch()}
              >
                re-generate
              </Typography>{' '}
              for category?
            </Typography>
          </Box>
        )}
        <Box sx={styles.btns}>
          {functions.includes('attribute') && (
            <ButtonWithLoading
              variant="contained"
              sx={styles.btn}
              onClick={handleAttributeSave}
              disabled={!enhancedAttribute}
              loading={isRestoreAIGenerationLoading}
            >
              <SaveIcon />
              Save Attribute
            </ButtonWithLoading>
          )}
          {functions.includes('instruction') && (
            <ButtonWithLoading
              variant="contained"
              sx={styles.btn}
              onClick={handleInstructionSave}
              disabled={!enhancedInstruction}
              loading={isWalmartAttributePatchLoading || isRetailerAttributePatchLoading}
            >
              <SaveIcon />
              Save Instruction
            </ButtonWithLoading>
          )}
          <ButtonWithLoading
            variant="contained"
            sx={styles.btn}
            onClick={handleEnhance}
            disabled={!comment}
            loading={isEnhanceInstructionLoading || isGenerateAILoading}
          >
            <AutoAwesomeIcon />
            Enhance Attribute
          </ButtonWithLoading>
        </Box>
      </Box>
    </Popover>
  );
}
