import ReactDOM from 'react-dom/client';
import toast, { Toaster } from 'react-hot-toast';
import { BrowserRouter as Router } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { ThemeProvider } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { InternalAuthProvider } from 'root/auth';
import { UserbackProvider } from '@userback/react';
import { AxiosError } from 'axios';
import { BackendErrorToast, RuntimeError } from 'root/errors';
import { theme } from 'common/constants/theme';
import { BackendErrorResponse } from 'common/types/common';
import * as Sentry from '@sentry/react';
import { DemoContext } from 'common/ui/containers/demo-context';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { StatusesContainer } from 'common/ui/shared/statuses-container';
import { App } from './root';
import './config/i18n';
import './config/sentry';

const root = ReactDOM.createRoot(document.getElementById('root'));

const handleReqError = (error: unknown) => {
  if (error instanceof AxiosError) {
    const backendError = error as AxiosError<BackendErrorResponse>;
    backendError.response.data?.errors?.forEach(error => {
      toast.error(<BackendErrorToast error={error} />, { duration: 10000 });
    });
  }
};

root.render(
  <Sentry.ErrorBoundary fallback={<RuntimeError />}>
    <UserbackProvider token={process.env.REACT_APP_USERBACK_KEY}>
      <ThemeProvider theme={theme}>
        <Auth0Provider
          domain={process.env.REACT_APP_AUTH0_DOMAIN}
          clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
          redirectUri={window.location.origin}
          audience="https://advon.ai"
          cacheLocation="localstorage"
        >
          <Toaster toastOptions={{ style: { maxWidth: '30vw', paddingRight: 0 } }} />
          <QueryClientProvider
            client={
              new QueryClient({
                defaultOptions: {
                  queries: { refetchOnWindowFocus: false, staleTime: 3 * (60 * 1000), cacheTime: 5 * (60 * 1000) },
                  mutations: { onError: error => handleReqError(error) },
                },
              })
            }
          >
            {process.env.NODE_ENV === 'development' && <ReactQueryDevtools />}
            <Router>
              <InternalAuthProvider>
                <StatusesContainer>
                  <DemoContext>
                    <App />
                  </DemoContext>
                </StatusesContainer>
              </InternalAuthProvider>
            </Router>
          </QueryClientProvider>
        </Auth0Provider>
      </ThemeProvider>
    </UserbackProvider>
  </Sentry.ErrorBoundary>
);
