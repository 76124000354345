import { useMutation } from '@tanstack/react-query';
import { RetailerType } from 'common/constants/entities';
import { productsApi } from 'common/services/api/products/products-api.service';
import {
  EnsureProductBody,
  ExportProductsCsvBody,
  ResetValueBody,
  SaveScrapedAttributesData,
  SuggestCategoriesData,
  TargetProductsFilters,
} from 'common/services/api/products/products-api.types';
import { userService } from 'common/services/user.service';
import { ScopeId } from 'common/types/common';

export function useResetValueMutation() {
  const mutation = useMutation({
    mutationFn: async (filters: ResetValueBody) => {
      const retailer = userService.getAPIRetailer().toLowerCase();
      return productsApi.resetValue(filters, retailer);
    },
  });

  return mutation;
}

export function useExportTargetProductsCsv(args: ExportProductsCsvBody) {
  const mutation = useMutation({
    mutationFn: async () => {
      const retailer = userService.getAPIRetailer().toLowerCase();
      const { data } = await productsApi.exportTargetCsv(args, retailer);
      return data.id;
    },
  });

  return mutation;
}

export function useExportAmazonProducts(args: ExportProductsCsvBody) {
  const mutation = useMutation({
    mutationFn: async () => {
      const { data } = await productsApi.exportAmazonProducts(args);
      return data.id;
    },
  });

  return mutation;
}

export function usePerformOCRMutation() {
  const mutation = useMutation({
    mutationFn: async (pids: Array<string>) => {
      const retailer = userService.getAPIRetailer() as RetailerType;

      const { data } = userService.ensureRetailer(RetailerType.Walmart)
        ? await productsApi.performWalmartOCR(pids)
        : await productsApi.performTargetOCR(retailer, pids);
      return data;
    },
  });

  return mutation;
}

export function useSuggestCategoryMutation() {
  const mutation = useMutation({
    mutationFn: async (data: SuggestCategoriesData) => {
      return data?.photos?.length ? productsApi.suggestCategoriesWithFiles(data) : productsApi.suggestCategories(data);
    },
  });

  return mutation;
}

export function useEnsureProductMutation() {
  const mutation = useMutation({
    mutationFn: async (body: EnsureProductBody) => {
      return productsApi.ensureProduct(body?.retailer, body);
    },
  });

  return mutation;
}

export function useDownloadCSVMutation() {
  const mutation = useMutation({
    mutationFn: async (body: { retailer: RetailerType; pid: string }) => {
      return productsApi.downloadCSV(body?.retailer, body?.pid);
    },
  });

  return mutation;
}

export function useSaveScrapedAttributesMutation() {
  const mutation = useMutation({
    mutationFn: async (params: SaveScrapedAttributesData) => {
      const { data } = await productsApi.saveScrapedAttributesData(
        userService.getAPIRetailer() as RetailerType,
        params
      );
      return data;
    },
  });

  return mutation;
}

export function useUpdateProductCategoryMutation() {
  const mutation = useMutation({
    mutationFn: async (params: { tcin: string; categoryId: string }) => {
      const { data } = await productsApi.updateProductCategory(
        userService.getAPIRetailer() as RetailerType,
        params?.tcin,
        params?.categoryId
      );
      return data;
    },
  });

  return mutation;
}

export function useImportProductsMutation() {
  const mutation = useMutation({
    mutationFn: async (args: TargetProductsFilters) => {
      const retailer = userService.getAPIRetailer() as RetailerType;
      const { data } = await productsApi.getProducts(retailer, args);
      return data;
    },
  });

  return mutation;
}

export function useAddImagesXlsxMutation() {
  const mutation = useMutation({
    mutationFn: async (files: File[]) => {
      const retailer = userService.getAPIRetailer() as RetailerType;
      return productsApi.addImagesXlsx(retailer, files);
    },
  });

  return mutation;
}

export function useDeleteProductMutation(body: { scope: ScopeId; id: string }) {
  const mutation = useMutation({
    mutationFn: async () => {
      const retailer = userService.getAPIRetailer() as RetailerType;

      const { data } = userService.ensureRetailer(RetailerType.Walmart)
        ? await productsApi.deleteWalmartProductById(body?.scope, body?.id)
        : await productsApi.deleteRetailerProductById(retailer, body?.scope, body?.id);
      return data;
    },
  });

  return mutation;
}
