import { Box, IconButton, Typography } from '@mui/material';
import { getProtectedRoutes, getUnProtectedRoutes } from 'common/constants/routes';
import { theme } from 'common/constants/theme';
import { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { matchPath, Outlet, useLocation, useNavigate } from 'react-router-dom';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useMenuWidth } from 'common/hooks/use-menu-width';
import { useHeadHeight } from 'common/hooks/use-head-height';
import { useContentPadding } from 'common/hooks/use-content-padding';
import { useMeQuery } from 'common/hooks/api/queries/use-users-query';
import { usePathsMatch } from 'common/hooks/use-paths-match';
import { userService } from 'common/services/user.service';
import { RetailerType } from 'common/constants/entities';
import { AuthUserControls } from 'common/ui/shared/auth-user-controls';
import { Styles } from 'common/types/styles';
import { Menu } from './menu';
import { HEAD_PORTAL_ID } from './head-portal';

const productsPaths = ['/content/:pid', '/product/:pid', '/pdp/:pid'];

interface StylesProps {
  menuOpen: boolean;
  userImage: string;
  headHeight: number;
  menuWidth: number;
  paddingY: number;
  paddingX: number;
}

const getStyles = ({ menuOpen, userImage, headHeight, menuWidth, paddingX, paddingY }: StylesProps): Styles => ({
  container: { display: 'flex', height: '100%', backgroundColor: theme.palette.background.default },
  headerContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    boxSizing: 'border-box',
    paddingLeft: menuOpen ? `${menuWidth}px` : 0,
    transition: 'padding-left 0.225s ease-in-out',
    width: '100%',
  },
  contentContainer: {
    minHeight: `calc(100vh - ${headHeight}px)`,
    padding: theme.spacing(`${paddingY}px`, `${paddingX}px`),
    boxSizing: 'border-box',
  },
  headContainer: {
    display: headHeight === 0 ? 'none' : 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#FAFAFA',
    height: headHeight,
    padding: theme.spacing(0, 3.5),
    boxSizing: 'border-box',
    gap: 6,
  },
  pagePreviewContainer: { display: 'flex', alignItems: 'center', gap: 0.5 },
  pagePreview: { color: '#7F8299', fontSize: 14 },
  backIcon: { color: '#7F8299', width: 10, height: 10 },
  loadingContainer: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  userImage: {
    width: 36,
    height: 36,
    minWidth: 36,
    borderRadius: '50%',
    backgroundColor: theme.palette.grey[300],
    backgroundImage: `url("${userImage}")`,
    backgroundSize: 'contain',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
  },
  portal: { flexGrow: 1 },
});

export function Layout(): ReactElement {
  const location = useLocation();
  const nav = useNavigate();
  const { data: meData } = useMeQuery();

  const forceNavigateToProducts = usePathsMatch(productsPaths);

  const topAnchorRef = useRef<HTMLDivElement>();

  const [menuOpen, setMenuOpen] = useState<boolean>(true);

  const headHeight = useHeadHeight();
  const menuWidth = useMenuWidth();
  const { paddingX, paddingY } = useContentPadding();

  const styles = getStyles({ menuOpen, userImage: meData?.user.picture, headHeight, menuWidth, paddingX, paddingY });

  const currentPage = useMemo(() => {
    const protectedRoutes = getProtectedRoutes();
    const routes = [...protectedRoutes, ...getUnProtectedRoutes()];
    const page = routes.find(r => matchPath(r.path, location.pathname)?.pathname);
    return page?.preview;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const toggleMenu = () => {
    setMenuOpen(menuOpen => !menuOpen);
  };

  const getProductsListPath = () => {
    return userService.ensureRetailer(RetailerType.Walmart) ? '/content' : '/';
  };

  useEffect(() => {
    if (!topAnchorRef.current) return;

    setTimeout(() => {
      topAnchorRef.current.scrollIntoView({ behavior: 'smooth' });
    });
  }, [topAnchorRef, location.pathname]);

  return (
    <Box sx={styles.container}>
      <Menu open={menuOpen} />
      <Box sx={styles.headerContentContainer}>
        <Box sx={styles.headContainer}>
          <Box sx={styles.pagePreviewContainer}>
            <IconButton onClick={() => (forceNavigateToProducts ? nav(getProductsListPath()) : nav(-1))} size="large">
              <ArrowBackIosNewIcon sx={styles.backIcon} />
            </IconButton>
            <Typography sx={styles.pagePreview}>{currentPage}</Typography>
          </Box>
          <Box sx={styles.portal} id={HEAD_PORTAL_ID} />
          <AuthUserControls />
        </Box>
        <Box sx={styles.contentContainer}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
}
