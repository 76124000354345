import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Popover,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { Styles } from 'common/types/styles';
import { FieldLabelWrapper } from 'common/ui/inputs/field-label-wrapper';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import SaveIcon from '@mui/icons-material/Save';
import { ChangeEvent, ReactElement, SyntheticEvent, useEffect, useState } from 'react';
import {
  useGenerateAIForTCIN,
  useRestoreAIGenerationMutation,
  useSendEnhanceNotificationMutation,
} from 'common/hooks/api/mutations/use-attributes-mutation';
import { ButtonWithLoading } from 'common/ui/containers/button-with-loading';
import { useQueryClient } from '@tanstack/react-query';
import { ContentQueryKey, useContentQuery } from 'common/hooks/api/queries/use-content-query';
import { userService } from 'common/services/user.service';
import { PocRetailers, RetailerType, SyndigoRetailers } from 'common/constants/entities';
import {
  EnhancePDPTargetBody,
  GenerateAIForWPIDSingleAttributeBody,
  GenerateAiResponse,
  GenereateAIForTCINBody,
} from 'common/services/api/attributes/attributes-api.types';
import { getPDPValues } from 'common/services/attributes.service';
import { AxiosResponse } from 'axios';
import toast from 'react-hot-toast';
import { ProductsQueryKey } from 'common/hooks/api/queries/use-products-query';
import { LightTooltip } from 'common/ui/containers/light-tooltip';
import InfoIcon from '@mui/icons-material/Info';

type Notification = {
  prevAttribute: string;
  newAttribute: string;
  status: 'empty' | 'pending' | 'ready';
  comment: string;
};

const pdpAttributes = ['product_title', 'product_short_description', 'product_long_description'];

const initPDPObj = () => {
  return pdpAttributes.reduce((acc, item) => {
    acc[item] = '';
    return acc;
  }, {} as Record<string, string>);
};

const initNotifyObj = () => {
  return pdpAttributes.reduce((acc, item) => {
    acc[item] = {
      prevAttribute: '',
      newAttribute: '',
      comment: '',
      status: 'empty',
    };
    return acc;
  }, {} as Record<string, Notification>);
};

enum TabValue {
  ProductTitle = 0,
  ProductShortDescription,
  ProductLongDescription,
}

const TabFieldNameObj = {
  [TabValue.ProductTitle]: 'product_title',
  [TabValue.ProductShortDescription]: 'product_short_description',
  [TabValue.ProductLongDescription]: 'product_long_description',
};

const EnhancePDPNameObj = {
  [TabValue.ProductTitle]: 'title',
  [TabValue.ProductShortDescription]: 'features',
  [TabValue.ProductLongDescription]: 'description',
};

const styles: Styles = {
  container: { width: 700, p: 2, boxSizing: 'border-box', display: 'flex', flexDirection: 'column', gap: 2 },
  btns: { display: 'flex', gap: 2 },
  btn: { flexGrow: 1 },
  checkboxes: { display: 'flex', flexDirection: 'column', ml: 1, mb: 2 },
  label: { fontSize: 14 },
  labelTip: { display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 },
};

interface Props {
  anchorEl: HTMLElement;
  onClose: () => void;
  pid: string;
}

export function TargetPDPEnhancePopup({ anchorEl, onClose, pid }: Props): ReactElement {
  const queryClient = useQueryClient();

  const { mutate: mutateGenerateRetailerAI, isLoading: isRetailerGenerateAILoading } = useGenerateAIForTCIN();
  const { mutate: mutateRestoreAIGeneration, isLoading: isRestoreAIGenerationLoading } =
    useRestoreAIGenerationMutation();
  const { mutate: sendNotification } = useSendEnhanceNotificationMutation();

  const [selectedTab, setSelectedTab] = useState<TabValue>(TabValue.ProductTitle);
  const [comments, setComments] = useState<Record<string, string>>(initPDPObj());
  const [notification, setNotification] = useState<Record<string, Notification>>(initNotifyObj());
  const [pdpGeneration, setPdpGeneration] = useState<boolean>(false);
  const [pdpSeoOptimization, setPdpSeoOptimization] = useState<boolean>(false);
  const [enhancePdpData, setEnhancePdpData] = useState<EnhancePDPTargetBody | null>(null);

  const { data: contentData } = useContentQuery({ pid, enabled: true });

  const isGenerateAILoading = isRetailerGenerateAILoading;

  const isSEOOptimizationAvailable =
    userService.ensureRetailer(RetailerType.Target) ||
    userService.ensureRetailer(RetailerType.Instacart) ||
    userService.ensureRetailers(SyndigoRetailers) ||
    userService.ensureRetailer(RetailerType.Autozone) ||
    userService.ensureRetailer(RetailerType.Amazon);

  const optimized = (enhancePdpData?.optimizations?.optimized ?? {})[EnhancePDPNameObj[selectedTab] as 'title'];
  const seoOptimized = (enhancePdpData?.optimizations?.seo_optimized ?? {})[EnhancePDPNameObj[selectedTab] as 'title'];

  const handleTabChange = (_: SyntheticEvent<Element, Event>, value: TabValue) => {
    setSelectedTab(value);
  };

  const handleCommentChange = (e: ChangeEvent<HTMLInputElement>) => {
    setComments(comments => ({ ...comments, [e.target.name]: e.target.value }));
  };

  const remapNotification = (
    notification: Record<string, Notification>,
    pdp: ReturnType<typeof getPDPValues>,
    attributeSource: 'prevAttribute' | 'newAttribute',
    status: 'empty' | 'pending' | 'ready'
  ) => {
    const copiedNotification = { ...notification };

    pdpAttributes.forEach(key => {
      copiedNotification[key].comment = comments[key] ?? '-';
      copiedNotification[key].status = status;

      switch (key) {
        case 'product_title': {
          copiedNotification[key][attributeSource] = pdp.ai.title ?? '-';
          break;
        }
        case 'product_short_description': {
          copiedNotification[key][attributeSource] = pdp.ai.desc ?? '-';
          break;
        }
        case 'product_long_description': {
          copiedNotification[key][attributeSource] = pdp.ai.feats ?? '-';
          break;
        }
        default:
          break;
      }
    });

    return copiedNotification;
  };

  const handleEnhance = () => {
    const body = {
      generateAttributes: false,
      optimizeAttributes: true,
      pdpAttributesWithIssuesOnly: false,
    };

    const pocBody = {
      optimizeAttributes: true,
      overwriteOptimizedAttributes: true,
    };

    const commonAttributeBody: Partial<GenerateAIForWPIDSingleAttributeBody & GenereateAIForTCINBody> = {
      ...(userService.ensureRetailer(RetailerType.Walmart) || userService.ensureRetailer(RetailerType.Flywheel)
        ? { wpid: pid }
        : { tcin: pid }),
      ...(!userService.ensureRetailers(PocRetailers) ? body : pocBody),
      ...(isSEOOptimizationAvailable ? { pdpGeneration, pdpSeoOptimization } : {}),
      comments,
      dryRun: true,
      overwriteOptimizedAttributes: true,
    };

    const pdp = getPDPValues(contentData);
    setNotification(remapNotification(notification, pdp, 'prevAttribute', 'pending'));

    const handleAttributeSuccess = ({ data }: AxiosResponse<GenerateAiResponse>) => {
      queryClient.invalidateQueries([ContentQueryKey.Content, pid]);

      const value = (data?.aiContentPreview ?? {}) as EnhancePDPTargetBody;

      setEnhancePdpData(value);
    };

    mutateGenerateRetailerAI(commonAttributeBody as GenereateAIForTCINBody, { onSuccess: handleAttributeSuccess });
  };

  const handlePropagateComment = () => {
    setComments(comments =>
      Object.keys(comments).reduce((acc, item) => {
        acc[item] = comments[TabFieldNameObj[selectedTab]];
        return acc;
      }, {} as Record<string, string>)
    );
  };

  const handlePdpGenerationChange = (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setPdpGeneration(checked);
  };

  const handlePdpSeoOptimization = (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setPdpSeoOptimization(checked);
  };

  const handleAttributesSave = () => {
    const handleSuccess = (type: string) => {
      toast.success(`Attributes (${type}) saved successfully`);

      queryClient.invalidateQueries([ContentQueryKey.Content, pid]);
      queryClient.invalidateQueries([ContentQueryKey.ContentList]);
      queryClient.invalidateQueries([ProductsQueryKey.GetProducts]);
    };

    if (optimized) {
      mutateRestoreAIGeneration(
        {
          tcin: pid,
          attributeName: 'optimized_pdp',
          generatedAt: enhancePdpData?.optimizations?.optimized?.generated_at ?? '',
        },
        { onSuccess: () => handleSuccess('PDP Optimization') }
      );
    }

    if (seoOptimized) {
      mutateRestoreAIGeneration(
        {
          tcin: pid,
          attributeName: 'seo_optimized_pdp',
          generatedAt: enhancePdpData?.optimizations?.seo_optimized?.generated_at ?? '',
        },
        { onSuccess: () => handleSuccess('PDP SEO Optimization') }
      );
    }
  };

  useEffect(() => {
    if (anchorEl) return;

    setComments(initPDPObj());
    setEnhancePdpData(null);
    // setEnhancedAttributes(initPDPObj());
  }, [anchorEl]);

  useEffect(() => {
    if (!contentData || notification.product_title?.status !== 'pending') return;

    const pdp = getPDPValues(contentData);
    setNotification(remapNotification(notification, pdp, 'newAttribute', 'ready'));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentData]);

  useEffect(() => {
    if (notification.product_title?.status !== 'ready') return;

    const notificationEntries = Object.entries(notification);

    sendNotification({
      attributeName: pdpAttributes.join(';'),
      comment: notificationEntries.map(([key, value]) => `${key}: ${value.comment}`).join(';'),
      currentAttributeValue: notificationEntries.map(([key, value]) => `${key}: ${value.prevAttribute}`).join(';'),
      enhancedAttributeValue: notificationEntries.map(([key, value]) => `${key}: ${value.newAttribute}`).join(';'),
    });

    setNotification(initNotifyObj());
  }, [notification, sendNotification]);

  return (
    <Popover anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onClose}>
      <Box sx={styles.container}>
        <Tabs onChange={handleTabChange} value={selectedTab} centered>
          <Tab value={TabValue.ProductTitle} tabIndex={TabValue.ProductTitle} label="Title" />
          <Tab
            value={TabValue.ProductLongDescription}
            tabIndex={TabValue.ProductLongDescription}
            label="Long Description"
          />
          <Tab
            value={TabValue.ProductShortDescription}
            tabIndex={TabValue.ProductShortDescription}
            label="Short Description"
          />
        </Tabs>
        <FieldLabelWrapper label="Comment">
          <TextField
            minRows={3}
            maxRows={3}
            onChange={handleCommentChange}
            value={comments[TabFieldNameObj[selectedTab]]}
            name={TabFieldNameObj[selectedTab]}
            multiline
            fullWidth
          />
        </FieldLabelWrapper>
        {isSEOOptimizationAvailable && (
          <Box sx={styles.checkboxes}>
            {userService.ensureRetailers([RetailerType.Target, RetailerType.Amazon]) && (
              <FormControlLabel
                control={<Checkbox checked={pdpGeneration} onChange={handlePdpGenerationChange} />}
                // eslint-disable-next-line react/no-unescaped-entities
                label={
                  <Box sx={styles.labelTip}>
                    <Typography sx={styles.label}>PDP Optimization</Typography>
                    <LightTooltip title="Updates copy according to best practices. Includes grammar, length, and structure changes to the original copy.">
                      <IconButton size="small">
                        <InfoIcon fontSize="small" />
                      </IconButton>
                    </LightTooltip>
                  </Box>
                }
              />
            )}

            <FormControlLabel
              control={<Checkbox checked={pdpSeoOptimization} onChange={handlePdpSeoOptimization} />}
              // eslint-disable-next-line react/no-unescaped-entities
              label={
                <Box sx={styles.labelTip}>
                  <Typography sx={styles.label}>PDP SEO Optimization</Typography>
                  <LightTooltip title="Inserts keywords surgically based on search data into either original copy or PDP optimized copy.">
                    <IconButton size="small">
                      <InfoIcon fontSize="small" />
                    </IconButton>
                  </LightTooltip>
                </Box>
              }
            />
          </Box>
        )}

        {optimized && !isGenerateAILoading && (
          <FieldLabelWrapper label="Enhanced Attribute (PDP Optimization)">
            <Typography fontSize={14}>{optimized?.value ?? '-'}</Typography>
          </FieldLabelWrapper>
        )}

        {seoOptimized && !isGenerateAILoading && (
          <FieldLabelWrapper label="Enhanced Attribute (PDP SEO Optimization)">
            <Typography fontSize={14}>{seoOptimized?.value ?? '-'}</Typography>
          </FieldLabelWrapper>
        )}

        <Box sx={styles.btns}>
          <Button sx={styles.btn} variant="outlined" onClick={handlePropagateComment}>
            Propagate Comment
          </Button>
          <ButtonWithLoading
            variant="contained"
            sx={styles.btn}
            onClick={handleAttributesSave}
            disabled={!enhancePdpData}
            loading={isRestoreAIGenerationLoading}
          >
            <SaveIcon />
            Save Attributes
          </ButtonWithLoading>
          <ButtonWithLoading
            variant="contained"
            sx={styles.btn}
            onClick={handleEnhance}
            loading={isGenerateAILoading}
            disabled={!pdpGeneration && !pdpSeoOptimization}
          >
            <AutoAwesomeIcon />
            Enhance Attributes
          </ButtonWithLoading>
        </Box>
      </Box>
    </Popover>
  );
}
